* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Sora", sans-serif;
}

body {
  font-size: 100%;
}

/* HOME PAGE */

.top-image {
  width: 100%;
}

.home-titles {
  text-align: center;
  color: #011F2F;
}

.info-1 {
  text-align: center;
  line-height: 1.2;
  padding: 20px;
}

.info-1 div {
  transition: all 0.5s;
}

.info-1 div:hover {
  transform: scale(1.05);
}

.info-1 img {
  width: 200px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.info-1 h2 {
  color: #011F2F;
  margin-bottom: 20px;
}

.info-icon {
  font-size: 100px;
  margin-bottom: 20px;
}

.hr {
  border: 4px solid orange;
}

.hr-2 {
  border: 2px solid orange;
}

.container-carousel {
  margin: 8px;
}

.carousel-indicators {
  visibility: hidden;
}

.carousel-inner {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 2px 6px 8px 2px #888888;
}

.container-banks {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.grid-item {
  width: 180px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.grid-item-bradesco {
  width: 250px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.social-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 6px;
}

.social-media p {
  padding-left: 20px;
  padding-right: 20px;
}

.social-media button {
  padding: 16px;
  margin: 10px;
  background-color: #010F16;
  color: white;
  border-radius: 10px;
  border: 1px solid lightgray;
  cursor: pointer;
}

.social-media button:hover {
  background-color: azure;
  color: #010F16;
  border: 1px solid #010F16;
}

.social-media-buttons {
  display: flex;
}

.social-media-buttons img {
  width: 80px;
  margin: 20px;
}

.whatsapp-button {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  border-radius: 50px;
  padding: 4px;
  z-index: 100;
}

.accordion-container {
  margin: 10px;
}

.accordion-body {
  text-align: justify;
}

/* HEADER */

.navbar-logo {
  width: 80px;
}

.container-navbar-root {
  background-color: #010F16 !important;
}

.container-navbar {
  background-color: #010F16 !important;
}

.nav-link-text {
  color: white !important;
  font-size: larger !important;
}

.nav-link-text:visited {
  color: white;
}

.nav-link-text:hover {
  color: orange !important;
}

/* FORM */

.form-header {
  text-align: center;
  color: #011F2F;
  margin: 20px;
}

.form-header h4 {
  font-size: large;
}

.form {
  margin: 10px;
  box-shadow: 0 4px 8px -1px rgba(0, 0, 0, .50);
  border-radius: 10px;
  padding: 20px;
  background-color: rgb(255, 255, 255);
}

.asterisco {
  color: red;
}

.text-input-label {
  font-weight: 700;
  margin-top: 16px;
  display: inline-block;
}

.form p {
  font-weight: 700;
  margin-top: 16px !important;
  margin-bottom: 10px;
}

.input-radio-label {
  margin-left: 10px;
}

.text-input {
  padding: 10px;
  font-size: large;
  margin-top: 6px;
  width: 100%;
  border-radius: 6px;
  border: 2px solid lightgray;
}

.text-input:focus {
  border: 2px solid rgb(66, 133, 244);
}

.submit-button {
  padding: 10px;
  font-size: large;
  width: 100%;
  margin-top: 20px;
  border-radius: 6px;
  border: 1px solid lightgray;
  cursor: pointer;
  background-color: #010F16;
  color: azure;
}

.submit-button:hover {
  background-color: azure;
  color: #010F16;
  border: 1px solid #010F16;
}

.form-privacy {
  text-align: center;
  font-size: small;
  margin-top: 10px;
}

/* THANK YOU PAGE */

.thank-you-container {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back-button {
  padding: 10px;
  font-size: large;
  width: 80%;
  margin-top: 6px;
  border-radius: 10px;
  border: 1px solid lightgray;
  cursor: pointer;
  background-color: #011F2F;
  color: azure;
}

.back-button:hover {
  background-color: azure;
  color: #011F2F;
  ;
}

/* VIDEO */

.container-video {
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
  height: 0;
  margin: 10px;
  border-radius: 20px;
  border: 2px solid #6EC1E4;
}

.container-video iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* FOOTER */

.footer-1 {
  text-align: center;
  padding: 30px;
  background-color: #011F2F;
  color: white;
  line-height: 1.5;
}

.footer-2 {
  background-color: #010F16;
  color: white;
  padding: 20px;
  text-align: center;
}

.footer-2 a {
  text-decoration: none;
}

/* ABOUT PAGE */

.about-info {
  margin: 40px;
  text-align: center;
}

.about-info p {
  text-align: justify;
  text-indent: 40px;
}

.about-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: all 0.5s;
}

.about-image:hover {
  transform: scale(0.98);
}

.about-info a {
  text-decoration: none;
}

/* CONTACT PAGE */

.contact-info {
  margin: 20px;
}

.form-2 {
  margin: 20px;
  box-shadow: 0 4px 8px -1px rgba(0, 0, 0, .50);
  border-radius: 10px;
  padding: 20px;
  background-color: rgb(255, 255, 255);
}

.text-input-label-2 {
  font-weight: 700;
  margin-top: 16px;
  text-align: start;
}

.form-textarea {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  margin-top: 8px;
  border: 2px solid lightgray;
  font-size: large;
  resize: none;
}

/* LEADS PAGE */

.leads {
  margin: 20px;
}

.clients-title {
  text-align: center;
  margin-top: 20px;
  color: #010F16;
}

.clients-user {
  text-align: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.clients-user-2 {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: fit-content;
}

.leads-new {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leads-count-text {
  color: green;
  font-size: larger;
}

.lead-button {
  cursor: pointer;
  margin: 6px;
}

.container-client {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lead-card {
  border-radius: 10px !important;
  padding: 20px;
  text-align: center;
  width: 90%;
  margin: 10px;
  line-height: 0.8;
  box-shadow: 0 4px 8px -1px rgba(0, 0, 0, .50);
}

.date {
  width: 100%;
  background-color: white;
  cursor: text;
  font-size: 10px;
  text-align: center;
  border: none;
}

.delete-button {
  padding: 10px;
  margin: 10px;
  background-color: darkred;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  font-size: large;
}

/* DASHBOARD PAGE */

.dashboard {
  padding: 20px;
}

.dashboard h3 {
  text-align: center;
}

/* LOGIN PAGE */

.login-form {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid orange;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 20px;
}

.login-input-label {
  font-weight: 700;
  margin-top: 16px;
}

.login-input {
  display: inline-block;
  padding: 10px;
  font-size: large;
  margin-top: 6px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid lightgray;
}

.login-btn {
  padding: 10px;
  border-radius: 20px;
  font-size: large;
  border: 1px solid lightgray;
  background-color: beige;
  cursor: pointer;
}

.container-show-password {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.login-checkbox {
  padding: 6px;
  transform: scale(1.5);
}

.login-checkbox-label {
  font-size: large;
  margin-left: 10px;
}

/* TEST */

.test {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.test h2 {
  text-align: center;
}

.test img {
  width: 80%;
  border-radius: 20px;
}

/* NO ARROWS ON NUMBER INPUT */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* MEDIA QUERIES */

@media only screen and (min-width: 768px) {

  .container-carousel {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .lead-card {
    width: 30%;
  }

  .info-1 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  .container-video {
    margin: 100px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .form {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .login-form {
    width: 40%;
  }

  .dashboard {
    width: 60%;
    margin: auto;
  }

  .contact-info {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .accordion-container {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .back-button {
    width: 50%;
  }

  .about-info {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}